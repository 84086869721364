<!--
 * @Author: cyy
 * @Date: 2022-01-25 10:37:29
 * @LastEditTime: 2022-12-15 17:39:12
 * @LastEditors: cyy
 * @Description: 公开课导出消息记录
 * @FilePath: \teacherdingding.com\src\views\LiveBroadcastManagement\openCourse\exportTemplate.vue
-->

<template>
  <div id="exportTemplate">
    <el-dialog
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title">
        <div class="flex-center">
          导出聊天记录
          <span class="redcolor ml10 fs12">(聊天记录仅保留7天，请悉知)</span>
          <!-- <span class="redcolor ml20 fs12">
            提示：将直播聊天记录导出到历史消息模板
          </span> -->
        </div>
      </div>
      <div class="dialogVisible_bottom">
        <div style="margin: -20px 0 0 0">
          <el-form
            label-position="left"
            :model="ruleForm"
            ref="ruleForm"
            label-width="120px"
            class="demo-ruleForm"
          >
            <el-form-item label="选择内容" :required="true">
              <el-radio-group v-model="ruleForm.type" @change="reset">
                <el-radio :label="1">按直播记录选</el-radio>
                <el-radio :label="2">按自定义时段选</el-radio>
              </el-radio-group>
              <div v-if="ruleForm.type == 1">
                <el-select
                  filterable
                  v-model="ruleForm.live_history_id"
                  style="width: 383px"
                  placeholder="选择直播记录"
                  @change="getMsgCount"
                >
                  <el-option
                    v-for="(item, index) in allHistoryList"
                    :key="index"
                    :label="getlabel(item)"
                    :value="item.live_history_id"
                  ></el-option>
                </el-select>
              </div>

              <div v-if="ruleForm.type == 2">
                <el-date-picker
                  v-model="ruleForm.date"
                  class="mr10"
                  :picker-options="pickerOptions"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                  @change="timePickerChange2"
                ></el-date-picker>
                <el-time-picker
                  is-range
                  v-model="ruleForm.time"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                  value-format="H:m:s"
                  @change="timePickerChange2"
                ></el-time-picker>
              </div>
              <div class="fs14" style="color: #666666">
                <span v-if="loadding">条数计算中...</span>
                <span v-else>共{{ msgCount }}条信息</span>
              </div>
            </el-form-item>

            <el-form-item label="导出到" :required="true">
              <el-radio-group v-model="ruleForm.exportPosition">
                <el-radio :label="1">本地Excel</el-radio>
                <el-radio :label="2">话术本-智能脚本</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item
              label="导出到话术本"
              v-if="ruleForm.exportPosition == 2"
              :required="true"
            >
              <el-select
                v-model="ruleForm.msg_id"
                style="width: 383px"
                placeholder="请选择话术本"
              >
                <el-option
                  v-for="item in tempplateList"
                  :key="item.msg_id"
                  :label="item.name"
                  :value="item.msg_id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="导入方式"
              v-if="ruleForm.exportPosition == 2"
              :required="true"
            >
              <el-radio-group v-model="ruleForm.import_type">
                <el-radio :label="2">合并</el-radio>
                <el-radio :label="1">覆盖</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" style="width: 87px" size="medium">
          取消
        </el-button>
        <el-button
          type="primary"
          style="width: 87px"
          @click="addTemplateSave"
          size="medium"
        >
          导出
        </el-button>
      </span>
    </el-dialog>

    <loading v-show="isNoData" leftPos="0"></loading>
  </div>
</template>

<script>
import loading from '@/components/LoadGif'
export default {
  name: 'exportTemplate',

  components: {
    loading,
  },

  data() {
    return {
      // 获取到的列表数据
      completeData: [],

      // 全选组件的状态
      isIndeterminate: false,

      // 单选框的数据
      overdue: 2,

      pickerOptions: {
        disabledDate(time) {
          let now = Date.now()
          let seven = 7 * 24 * 60 * 60 * 1000
          let sevenDays = now - seven
          return time.getTime() > now || time.getTime() < sevenDays
        },
      },
      loadding: false,
      tempplateList: [],
      isNoData: false,
      msgCount: 0,
      // 直播记录
      allHistoryList: [],
      templateName: '',
      ruleForm: {
        live_history_id: '',
        exportPosition: 1,
        type: 1,
        start_time: '',
        end_time: '',
        date: '',
        time: '',
        msg_id: '',
        import_type: 2,
      },
    }
  },

  props: {
    openCouseId: {
      type: String,
      default: undefined,
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.getallHistoryList()
    this.getTempplateList()
  },

  methods: {
    reset() {
      this.msgCount = 0
      this.ruleForm = _.assign({}, this.ruleForm, {
        live_history_id: '',
        exportPosition: 1,
        date: '',
        time: '',
        start_time: '',
        end_time: '',
      })
    },

    timePickerChange2() {
      // 点击x清除的时候传null
      const val = this.ruleForm.time
      if (val) {
        const date = this.ruleForm.date / 1000
        let start_time = val[0].split(':').map(item => Number(item))
        let end_time = val[1].split(':').map(item => Number(item))

        let startCount =
          start_time[0] * 60 * 60 + start_time[1] * 60 + start_time[2]

        let endCount = end_time[0] * 60 * 60 + end_time[1] * 60 + end_time[2]
        this.ruleForm.start_time = startCount + date
        this.ruleForm.end_time = endCount + date
      } else {
        this.ruleForm.start_time = ''
        this.ruleForm.end_time = ''
        this.msgCount = 0
      }
      this.getMsgCount()
    },

    // 获取公开课信息数量
    async getMsgCount() {
      this.cancelOption()
      if (
        (this.ruleForm.type == 2 &&
          this.ruleForm.start_time &&
          this.ruleForm.date) ||
        this.ruleForm.type == 1
      ) {
        this.getMsgCount2()
      } else {
        this.msgCount = 0
      }
    },

    // 取消请求
    cancelOption() {
      if (this.xhr) {
        this.xhr.abort()
      }
    },

    // 获取公开课信息数量
    getMsgCount2() {
      this.loadding = true
      this.xhr = this.$http({
        url: '/chatMessage/getMsgCount',
        data: _.assign({}, this.ruleForm, {
          open_class_id: this.openCouseId,
        }),
      })

      this.xhr.then(({ data }) => {
        this.msgCount = data
        this.loadding = false
      })
    },

    // 保存模板
    async addTemplateSave() {
      if (this.ruleForm.type == 1 && !this.ruleForm.live_history_id) {
        this.$root.prompt('请选择直播记录')
      } else if (
        this.ruleForm.type == 2 &&
        (!this.ruleForm.date ||
          !this.ruleForm.start_time ||
          !this.ruleForm.end_time)
      ) {
        this.$root.prompt('请选择时间')
      } else if (this.ruleForm.exportPosition == 2 && !this.ruleForm.msg_id) {
        this.$root.prompt('请选择模板')
      } else {
        this.isNoData = true

        const data = _.assign({}, this.ruleForm, {
          open_class_id: this.openCouseId,
        })

        if (this.ruleForm.exportPosition == 2) {
          try {
            await this.$http({
              url: '/chatMessage/saveHistory',
              data: data,
            })
            this.$root.prompt({
              msg: '导出成功',
              type: 'success',
            })
            this.isNoData = false
          } catch (e) {
            console.log(e)
          }
        } else {
          let eleLink = document.createElement('a')
          eleLink.href =
            `${location.origin}/ChatMessage/exportExecel` + this.objToStr(data)
          eleLink.click()
          eleLink.remove()
        }

        this.cancel()
      }
    },

    getlabel(val) {
      let text = ''
      const filter = this.$options.filters['formatTimeStamp']
      const filter2 = this.$options.filters['formatTimeLength']
      text =
        filter(val.start_time, 'yyyy-MM-dd hh:mm') +
        '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
        '时长：' +
        filter2(val.push_duration)
      return text
    },

    // 模板列表
    async getTempplateList() {
      const { data } = await this.$http({
        url: '/chatMessage/chatMsgList',
        data: {
          type: 3,
        },
      })
      this.tempplateList = data
    },

    // 获取公开课直播记录数据
    async getallHistoryList() {
      try {
        const { data } = await this.$http({
          url: '/openClass/allHistoryList',
          data: {
            type: 1,
            open_class_id: this.openCouseId,
          },
        })
        this.allHistoryList = data
      } catch (e) {
        console.log(e)
      }
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
#exportTemplate {
  .dialogVisible_bottom {
  }
}
</style>
