<template>
  <div id="openClass">
    <div
      class="list_main_contain"
      v-if="$route.name == 'publicClass' || $route.name == 'intelligence'"
    >
      <div class="header_des" v-if="fatherPath == '/intelligence'">
        智能直播是叮叮开课推出的全新直播模式，将录制好的视频，结合聊天室，营造出真实直播间的氛围；与普通视频直播不同，叮叮开课智能直播可根据视频不同的时间点，设定发言内容，当播放到该时间点时自动发言，无需实时守着直播间维护场控；叮叮开课智能直播还支持时间同步播放，保证所有学员观看的进度为统一的，毫无破绽，提高真实度。
      </div>
      <div style="background-color: #fff">
        <div class="header">
          <div class="btns1">
            <el-button
              v-if="fatherPath == '/intelligence'"
              type="primary"
              size="medium"
              @click="newdata"
            >
              新建智能直播
            </el-button>
            <el-button
              v-else
              type="primary"
              style="width: 109px"
              size="medium"
              @click="newdata"
            >
              新建公开课
            </el-button>

            <el-button
              class="ml20"
              type="text"
              @click="
                fatherPath == '/intelligence'
                  ? $store.dispatch(
                      'open',
                      'https://www.yuque.com/dingdingkaike/doc/kbe2kz'
                    )
                  : $store.dispatch(
                      'open',
                      'https://www.yuque.com/dingdingkaike/doc/qsd2dd'
                    )
              "
            >
              使用教程
            </el-button>
          </div>
          <div class="recharge">
            <div class="info">
              剩余叮豆：{{ $store.state.userInfo.t_amount }}
            </div>
            <el-button
              type="text"
              class="ml20"
              @click="$router.push({ path: '/capitalAccount/TopUpDingDou' })"
            >
              充值叮豆
            </el-button>
          </div>
        </div>
        <div class="condition">
          <el-select
            v-model="post.sort"
            style="width: 150px"
            size="medium"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <el-select
            v-model="post.status"
            style="width: 150px"
            size="medium"
            placeholder="请选择"
            class="ml20 mr20"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            v-model="post.search"
            size="medium"
            placeholder="输入公开课名称搜索"
            style="width: 218px"
          ></el-input>
        </div>
        <div class="contianmain">
          <pagination2
            :option="post"
            url="/openClass/openClassList"
            ref="childDialogBox"
            @complete="complete"
          >
            <template v-slot:default="{ tableData }">
              <ul class="ul1" v-if="tableData.length > 0">
                <li
                  class="item"
                  v-for="item in tableData"
                  :key="item.open_class_id"
                >
                  <div class="cover">
                    <el-image
                      class="img"
                      :src="item.cover_img | imageslim(4)"
                      lazy
                      alt=""
                    />
                    <div class="tip">
                      {{ item.status | getstatus }}
                      <span v-if="item.live_mode != 3">
                        -{{ item.live_mode | getlive_mode }}
                      </span>
                      (<span v-if="item.screen_type==2">竖屏</span>
                      <span v-else>横屏</span>)
                    </div>
                    <div
                      class="time"
                      v-if="
                        item.status != 1 &&
                          item.start_time != 0 &&
                          item.down_time > 0
                      "
                    >
                      距离开播：{{ item.down_time | formatTimeLength(2) }}
                    </div>
                  </div>
                  <div class="operation">
                    <div class="title" :title="item.name">{{ item.name }}</div>
                    <div class="courseinfo">
                      <span v-if="item.start_time > 0">
                        直播时间：{{
                          item.start_time | formatTimeStamp('yyyy-MM-dd hh:mm')
                        }}

                        <span style="margin: 0 5px">|</span>
                      </span>
                      直播老师：{{ item.uname }}
                    </div>
                    <div class="smartName" v-if="item.msg_name">
                      智能脚本：{{ item.msg_name }}
                    </div>
                    <div class="money">
                      <span class="redcolor" v-if="item.play_type != 2">
                        <span v-if="item.play_price > 0">
                          ￥{{ item.play_price }}
                        </span>
                        <span v-else>免费</span>
                      </span>
                      <span class="redcolor" v-else>
                        密码：{{ item.play_pwd }}
                      </span>

                      <span
                        class="strick"
                        v-if="item.play_type == 3 && item.cost_price > 0"
                      >
                        ￥{{ item.cost_price }}
                      </span>
                    </div>
                    <div class="coursebtns" :class="[item.msg_name&&'smartTop']">
                      <div class="leftbnt">
                        <!-- is_old 1老版本 2新版本 -->
                        <el-button
                          type="primary"
                          class="share"
                          style="color: #fff !important"
                          size="mini"
                          v-if="fatherPath == '/intelligence'&&item.is_old==1" 
                          @click="containManager(item)"
                        >
                          内容管理
                        </el-button>
                        <classesBegin
                          v-else-if="fatherPath == '/publicClass'"
                          class="vm table-classesBegin"
                          :item="item"
                          type="4"
                        >
                          <!-- :item="item" -->
                          <el-button
                            type="primary"
                            class="classbegin"
                            size="mini"
                          >
                            发起直播
                          </el-button>
                        </classesBegin>
                        <el-button
                          size="mini"
                          class="share ml10 mr10"
                          @click="share(item)"
                        >
                          分享直播
                        </el-button>
                        <el-button
                          v-if="fatherPath == '/intelligence'&&item.is_old==2&&item.status==1"
                          size="mini"
                          class="share stop ml10 mr10"
                          @click="stopLive(item)"
                        >
                          停止直播
                        </el-button>
                        <el-button
                          v-if="fatherPath == '/publicClass'"
                          size="mini"
                          class="share"
                          style="margin-left: 0"
                          @click="exportTemplateclick(item)"
                        >
                          导出聊天记录
                        </el-button>
                      </div>
                      <div class="rightbtn">
                        <!-- 详情 | 控制台 | 发通知 | 设置 | 编辑 | 更多 -->
                        <span
                          class="sp_name"
                          @click="detail(item.open_class_id)"
                        >
                          详情
                        </span>
                        <span class="sp_diver">|</span>
                        <span
                          class="sp_name"
                          @click="consoleClick(item.open_class_id)"
                        >
                          控制台
                        </span>
                        <span class="sp_diver">|</span>
                        <span class="sp_name" @click="pushsetingclick(item)">
                          推送设置
                        </span>
                        <span class="sp_diver">|</span>
                        <span class="sp_name" @click="editor(item)">编辑</span>
                        <span class="sp_diver">|</span>

                        <el-dropdown>
                          <span class="sp_name el-dropdown-link">更多</span>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                              <p @click="detail(item.open_class_id)">
                                成员管理
                              </p>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <p @click="dingyue(item.open_class_id)">
                                订阅管理
                              </p>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <p @click="invite(item.open_class_id)">
                                邀请记录
                              </p>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <p
                                style="color: #ff3535"
                                @click="del(item.open_class_id)"
                              >
                                删除
                              </p>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div v-else-if="iscomplete" class="empty">
                <span>暂无数据~</span>
              </div>
            </template>
          </pagination2>
        </div>
        <tip
          v-if="status"
          @sure="sure"
          :list="list"
          :dialogstatus.sync="status"
        ></tip>
        <shareinfo
          v-if="shareinfo"
          :dialogstatus.sync="shareinfo"
          :shareinfoval="shareinfoval"
          :fatherPath="fatherPath"
        ></shareinfo>
      </div>
    </div>
    <router-view />

    <!-- 导出模板 -->
    <export-template
      v-if="exportTemplatestatus"
      :dialogstatus.sync="exportTemplatestatus"
      :openCouseId="openCouseId"
    ></export-template>

    <push-settings
      v-if="status2"
      :pushseting="pushseting"
      :dialogstatus.sync="status2"
    ></push-settings>
    <livebroadcast-settings
      v-if="status3"
      :settingval="settingval"
      :dialogstatus.sync="status3"
    ></livebroadcast-settings>
  </div>
</template>

<script>
import exportTemplate from './exportTemplate'
import livebroadcastSettings from '@/components/livebroadcastSettings'
import pushSettings from './components/pushSettings'
import tip from './components/tip'
import shareinfo from '@/components/openCourse/shareinfo'
import bg from '@/mixin/background'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('OpenClassopenClassLive')

export default {
  name: 'openClass',

  mixins: [bg],

  components: {
    tip,
    shareinfo,
    pushSettings,
    livebroadcastSettings,
    exportTemplate,
  },

  data() {
    return {
      exportTemplatestatus: false,

      fatherPath: '',

      //数据是否已经获取完成
      iscomplete: false,

      //是否有进入控制台的权限
      consoleInfostatus: false,

      tipType: '',

      delid: '', //被删除的id

      settingval: '', //直播设置id

      shareinfoval: {}, //分享直播的数据

      status3: false,

      status2: false,

      post: {
        type: 1,
        sort: 0,
        status: 0,
        search: '',
      },

      shareinfo: false,

      list: {},

      status: false,

      options: [
        {
          value: 0,
          label: '默认排序',
        },
        {
          value: 1,
          label: '最近开播靠前',
        },
        {
          value: 2,
          label: '最近开播靠后',
        },
        {
          value: 3,
          label: '创建时间靠前',
        },
        {
          value: 4,
          label: '创建时间靠后',
        },
      ],

      options2: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '直播中',
        },
        {
          value: 2,
          label: '未直播',
        },
      ],
    }
  },

  filters: {
    getstatus(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '直播中'
          break
        case 2:
          text = '未直播'
          break
        case 3:
          text = '直播结束'
          break
      }
      return text
    },

    getlive_mode(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '标准直播'
          break
        case 2:
          text = '快直播'
          break
      }
      return text
    },
  },

  watch: {
    $route: {
      handler(value) {
        // 位于一级路由
        this.fatherPath = value.path
        this.post = {
          type: 1,
          sort: 0,
          status: 0,
          search: '',
        }
        this.post.type = this.fatherPath == '/publicClass' ? 1 : 2
      },
      immediate: true,
    },
  },

  computed: {},

  methods: {
    ...mapActions(['sendToWindowsSizeInOpenClass']),

    // 导出模板
    exportTemplateclick(item) {
      this.openCouseId = item.open_class_id
      this.$nextTick(() => {
        this.exportTemplatestatus = true
      })
    },

    containManager(val) {
      this.$router.push({
        path: '/intelligence/intelligenceManager',
        query: { open_class_id: val.open_class_id },
      })
    },

    complete() {
      this.iscomplete = true
    },

    //删除
    sure() {
      if (this.tipType == 1) {
        this.$http({
          url: '/openClass/delopenClass',
          data: {
            open_class_id: this.delid,
          },
          callback: () => {
            this.$root.prompt({
              type: 'success',
              msg: '操作成功',
            })
            this.$refs.childDialogBox.reset()
          },
          error: () => {
            this.$root.prompt('操作失败')
          },
        })
      }
      this.status = false
    },

    //分享直播
    share(item) {
      this.shareinfoval = item
      this.shareinfo = true
    },

    // 停止直播
    stopLive(item) {
      this.$confirm('是否停止直播！', {
        confirmButtonText: '停止',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
      }).then(async () => {
        try {
          await this.$http({
            url: '/openClass/stopLive',
            data: {
              open_class_id: item.open_class_id,
            },
          })
          this.$root.prompt({
            type: 'success',
            msg: '操作成功',
          })
          this.$refs.childDialogBox.reset()
        } catch (e) {
          this.$root.prompt(e||'操作失败')
        }
      })
    },

    //直播设置
    setting(val) {
      this.settingval = val
      this.status3 = true
    },

    //推送设置
    pushsetingclick(val) {
      this.pushseting = val
      this.status2 = true
    },

    //详情
    detail(val) {
      this.$router.push({ path: this.fatherPath + '/detail/' + val + '/' + 1 })
    },

    //订阅管理
    dingyue(val) {
      this.$router.push({ path: this.fatherPath + '/detail/' + val + '/' + 3 })
    },

    //邀请记录
    invite(val) {
      this.$router.push({ path: this.fatherPath + '/detail/' + val + '/' + 4 })
    },

    //编辑
    editor(val) {
      if (val.status == 1) {
        this.$root.prompt('直播中，不可编辑！')
        return
      }
      this.$router.push({
        path: `${this.fatherPath}/newData/${val.open_class_id} `,
      })
    },

    //新建
    newdata() {
      this.$router.push({
        path: `${this.fatherPath}/newData/` + 'new',
      })
    },

    consoleInfo(val) {
      return new Promise(resolve => {
        this.$http({
          url: '/console/checkTeacher',
          mute: true,
          data: {
            open_class_id: val,
          },
          callback: () => {
            this.consoleInfostatus = false

            resolve()
          },
          error: () => {
            this.consoleInfostatus = true
            resolve()
          },
        })
      })
    },

    //控制台
    consoleClick(val) {
      this.consoleInfo(val).then(() => {
        if (this.consoleInfostatus) {
          this.list = {
            type: 2,
            contain: '直播老师和该公开课助教老师可进入控制台！',
          }

          this.$nextTick(() => {
            this.tipType = 2
            this.status = true
          })
        } else {
          this.open_class_id = val
          //如果是客户端，则打开客户端
          //windo.qt是客户端带的
          // if (this.$store.state.isWindowsApp) {
          let url = this.$router.resolve({
            path: '/OpenClass_openClassLive/' + val,
          })
          this.$store.dispatch('open2', { type: 7, url: url.href })

          if (this.$store.state.isWindowsApp) {
            this.sendToWindowsSizeInOpenClass({ width: 300, height: 653 })
          }
        }
      })
    },

    //删除
    del(val) {
      this.delid = val
      this.list = {
        type: 1,
        contain: '是否删除该直播？可在回收站还原！',
      }
      this.$nextTick(() => {
        this.tipType = 1
        this.status = true
      })
    },

    // 解散清空
    async destory(val) {
      await this.$confirm(
        '是否确定解散并清空聊天记录？解散后已在直播间的学员会被踢出，需要重新进入！付费直播无需重新购买！',
        '温馨提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )

      this.$http({
        url: '/openClass/dissolution',
        data: {
          open_class_id: val,
        },
        callback: () => {
          this.$root.prompt({
            msg: '解散成功',
            type: 'success',
          })
        },
        error: () => {
          this.$root.prompt('操作失败')
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#openClass {
  margin: 20px;
  min-width: 868px;
  .list_main_contain {
    .header_des {
      background-color: #fff;
      font-size: 13px;
      color: #333333;
      padding: 10px 20px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
  .header {
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btns1 {
      display: flex;
    }
    .recharge {
      display: flex;
      align-items: center;
      .info {
        font-size: 14px;
        color: #333333;
        line-height: 14px;
      }
    }
  }
  .condition {
    display: flex;
    justify-content: flex-end;
    padding: 12px 20px 0px 0;
  }
  ::v-deep .contianmain {
    .empty {
      font-size: 15px;
      color: #999;
      display: flex;
      justify-content: center;
      height: 200px !important;
      align-items: center;
    }
    .ul1 {
      .item {
        display: flex;
        padding: 30px 20px;
        .cover {
          border-radius: 5px;
          width: 280px;
          height: 157px;
          background-color: grey;
          margin-right: 20px;
          position: relative;
          .el-image {
            width: 100%;
            height: 100%;
          }
          img {
            width: 100%;
            height: 100%;
          }
          .tip {
            padding: 6px 4px;
            font-size: 12px;
            color: #ffffff;
            line-height: 12px;
            position: absolute;
            top: 10px;
            left: 10px;
            background: rgba(0, 0, 0, 0.59);
            border-radius: 4px;
          }
          .time {
            border-radius: 0 0 5px 5px;
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.69);
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            line-height: 14px;
            letter-spacing: 1px;
            padding: 9px 0;
          }
        }
        .operation {
          width: calc(100% - 300px);
          .title {
            font-size: 16px;
            font-weight: bold;
            color: #363636;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .courseinfo {
            font-size: 13px;
            color: #666666;
            line-height: 13px;
            margin: 14px 0 14px;
          }
          .smartName {
            margin-bottom:20px;
            font-size: 13px;
            color: #A1A1A1;
            line-height: 13px;
          }
          .money {
            .redcolor {
              font-size: 14px;
              font-weight: bold;
              line-height: 14px;
            }
            .strick {
              margin-left: 6px;
              text-decoration: line-through;
              font-size: 12px;
              color: #666666;
              line-height: 14px;
            }
          }
          .coursebtns {
            margin-top: 49px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .leftbnt {
              .share,
              .classbegin {
                width: 100px !important;
                border-radius: 15px !important;
                border: 1px solid #0aa29b;
                color: #0aa29b !important;
              }
              .stop {
                border: 1px solid #ff3535;
                color: #fff !important;
                background-color: #ff3535;
              }
              .classbegin {
                color: #fff !important;
              }
            }
            .leftbnt {
              width: 324px;
              min-width: 324px;
            }
            .rightbtn {
              font-size: 13px;
              line-height: 13px;
              color: #0aa29b;
              .sp_name {
                color: #0aa29b;
                padding: 0 5px;
                line-height: 1.5;
                cursor: pointer;
              }
              ::v-deep .el-dropdown {
                font-size: 13px;
                color: #0aa29b;
              }
            }
          }
          .smartTop {
            margin-top: 14px;
          }
        }
      }
    }
  }
}
</style>
